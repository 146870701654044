<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <template v-if="errorMessages">
        <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </template>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>

      <div v-show="loading">
        <default-loading></default-loading>
      </div>
      <div class="card" v-show="!loading">
        <form class="card-body">
          <div class="row">
            <div class="col-xl-12 m-auto">
              <div class="form-group row mb-3">
                <label class="col-md-3 col-form-label" for="course_title">
                  {{ $t('collectionDetail.content_name') }}

                  <span class="required">*</span>
                </label>
                <div class="col-md-9">
                  <input @input="handleTitleChanges" v-model="form.title" type="text" class="form-control"
                    id="course_title" name="title" :placeholder="$t('collectionDetail.content_name_placeholder')"
                    required="" />
                </div>
              </div>

              <div class="form-group row mb-3">
                <label class="col-md-3 col-form-label" for="short_description">
                  {{ $t('collectionDetail.content_description') }}
                </label>
                <div class="col-md-9">
                  <textarea name="short_description" id="short_description" class="form-control"
                    v-model="form.description"></textarea>
                </div>
              </div>
              <div class="form-group row mb-3" v-if="false">
                <label class="col-md-3 col-form-label" for="logo">
                  {{ $t('collectionDetail.cover_image') }}
                  <span class="required">*</span>
                </label>
                <div class="col-md-9">
                  <div class="
                          position-relative
                          d-flex
                          justify-center justify-content-center">
                    <img style="height: 190px" class="rounded img-fluid logoarea" src="@/assets/images/no-photo.png"
                      alt="" />
                    <input type="file" class="d-none" id="logo" accept="image/*" @change="processFile($event)" />
                    <button @click="inputClick()" type="button" class="btn btn-default btn-icon-label"
                      style="top: 50%; position: absolute">
                      <span class="btn-inner--icon">
                        <i class="uil-image"></i>
                      </span>
                      <span class="btn-inner--text">{{
                        $t("general.image_change")
                      }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group row mb-2 input-checkbox">
                <div class="col-md-3">
                  <label class="col-form-label mb-1" for="pass">
                    {{ $t('collectionDetail.content_type') }}
                    <span class="required">*</span></label>
                </div>

                <div class="col-md-9">
                  <multiselect @input="changeOption" v-model="form.file_category_id"
                    :placeholder="$t('collectionDetail.content_type_placeholder')" label="name" track-by="id"
                    :options="formattedFileTypes" :allow-empty="false" :searchable="true" deselectLabel=""
                    :selectLabel="$t('general.select')" />
                </div>
              </div>
            </div>
            <div class="col-md-12" v-if="form.file_category_id">
              <h4 class="text-uppercase text-center mt-4">
                {{ $t(selected_content_type.key) }}

                {{ $t('collectionDetail.upload') }}
              </h4>
              <div v-if="selected_content_type.accept === 'id'">
                <div class="form-group">
                  <input type="number" v-model="form.url" placeholder="ID" min="0" class="form-control" />
                </div>
              </div>
              <div v-else-if="selected_content_type.accept === 'url'">
                <div class="form-group">
                  <input type="text" v-model="form.url" placeholder="https://www.example.com/document"
                    class="form-control" />
                </div>
              </div>

              <div class="uploadOuter" v-else>
                <label for="uploadFile" class="btn btn-info"><i class="uil-upload"></i>
                  {{ $t("general.upload") }}</label>
                <strong>{{ $t("general.or") }}</strong>
                <span class="dragBox">
                  <i :class="selected_content_type.icon"></i>
                  {{ $t(selected_content_type.key + "_drag_drop") }}
                  <input type="file" :accept="acceptable_file_type" draggable="true" @dragover.prevent @dragenter="drag"
                    @dragleave="drop" id="uploadFile" @change="dropped" />
                </span>
              </div>
              <div id="preview"></div>
            </div>
          </div>

          <button class="uk-button uk-button-primary small float-right" type="button" @click="saveForm()">
            <i class="icon-feather-save"></i>
            {{ $t('collectionDetail.save') }}

          </button>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/core/services";
import Multiselect from "vue-multiselect";
import module, {
  CONTENT_CATEGORIES,
  CONTENT_CATEGORIES_URL,
  FILE_TYPES,
  FILE_TYPES_URL,
  GET_CONTENT_CATEGORIES,
  GET_FILE_TYPES,
  MODULE_NAME,
} from "@/core/services/store/content_categories.module";
import contentModule, {
  BASE_URL,
  CREATE_ITEM,
  ERROR,
  LOADING,
  MODULE_NAME as CONTENT_MODULE_NAME,
} from "@/core/services/store/collection_external_content.module";

import DefaultLoading from "@/view/components/loading/DefaultLoading";


export default {
  name: "CollectionContentModal",
  components: { Multiselect, DefaultLoading },
  props: {
    collectionId: {
      required: true,
    }
  },
  data() {
    return {
      hashtags: [],
      isClosedHashtagSelector: false,
      title: "Başlık",
      hashtags_result: "Kategori Etiketleri Seçin",
      content_share: "Kapalı",
      file_type_selected: "İçerik Türünü Seçin",
      successMessage: "",
      errorMessages: [],
      acceptedFileCategoryIds: [2, 3, 4, 5, 6, 7, 8, 9],
      form: {
        title: "",
        description: "",
        file_category_id: null,
        cover_photo: null,
        files: [],
        url: null
      },
      acceptable_file_type: "*",
      selected_content_type: [],
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(MODULE_NAME, module);
    registerStoreModule(CONTENT_MODULE_NAME, contentModule);
  },
  computed: {
    loading() {
      return store.getters[CONTENT_MODULE_NAME + "/" + LOADING];
    },
    formattedFileTypes: {
      get() {
        let results = [];

        if (this.file_types === null) return [];
        for (let i = 0; i < this.file_types.length; i++) {
          let currentItem = this.file_types[i];
          if (currentItem && this.acceptedFileCategoryIds.includes(currentItem.id)) {
            results.push({
              icon: currentItem.icon,
              name: currentItem.name,
              key: currentItem.key_name,
              accept: currentItem.accept,
              id: currentItem.id,
            });
          }
        }
        return results;
      },
      set(value) {
      },
    },
    formattedContentCategories: {
      get() {
        let results = [];
        if (this.content_categories === null) return [];
        for (let i = 0; i < this.content_categories.length; i++) {
          let currentItem = this.content_categories[i];
          if (currentItem) {
            results.push({
              icon: currentItem.icon,
              name: currentItem.name,
              id: currentItem.id,
            });
          }
        }
        return results;
      },
      set(value) {
      },
    },
    file_types: {
      get() {
        return store.getters[MODULE_NAME + "/" + FILE_TYPES];
      },
      set(value) {
      },
    },
    content_categories: {
      get() {
        return store.getters[MODULE_NAME + "/" + CONTENT_CATEGORIES];
      },
      set(value) {
      },
    },
    error: {
      get() {
        return store.getters[CONTENT_MODULE_NAME + "/" + ERROR];
      },
      set(value) {
      },
    },
  },
  methods: {
    changeOption() {
      let id = this.form.file_category_id.id;
      if (id) {
        let ContentType = this.getContentTypeById(id);
        this.file_type_selected = ContentType.name;
        this.acceptable_file_type = ContentType.accept;
        this.selected_content_type = ContentType;
        this.form.files = [];
        this.form.url = null;
        $("#preview").html("");
        $("#uploadFile").val("");
      }
    },
    getContentTypeById(id) {
      let data;
      this.formattedFileTypes.forEach((item) => {
        if (item.id === id) {
          data = item;
        }
      });
      return data;
    },
    saveForm() {
      let form = this.form;
      let self = this;
      if (!this.isValidFields("ALL")) return false;

      if (this.acceptable_file_type === "url") {
        if (!form.url.includes("http")) form.url = "https://" + form.url;
      }

      let formData = new FormData();
      formData.append("title", form.title);
      formData.append("has_url", this.acceptable_file_type === "url" ? 1 : 0);
      formData.append("url", form.url);
      formData.append("description", form.description);
      formData.append("file_category_id", form.file_category_id.id);
      formData.append("collection_id", self.collectionId);

      if (form.files) formData.append("file", form.files);

      store
        .dispatch(CONTENT_MODULE_NAME + "/" + CREATE_ITEM, {
          url: BASE_URL,
          contents: formData,
        })
        .then(() => {
          // $("html, body").animate({ scrollTop: 0 }, "slow");
          if (!this.error) {
            this.successMessage = this.$t("general.successfully_created");

            setTimeout(() => {
              this.form = {
                title: "",
                description: "",
                file_category_id: null,
                cover_photo: null,
                files: [],
                url: null
              };
              self.$emit("onSubmit", {
                success: true
              });
            }, 1000);

          } else {
            this.errorMessages.push(this.error);
          }
        });
    },
    validateURL(url) {
      const res = url.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      return res !== null;
    },
    handleTitleChanges(e) {
      this.title = e.target.value;
    },
    getFileCategories() {
      this.$store.dispatch(MODULE_NAME + "/" + GET_FILE_TYPES, {
        url: FILE_TYPES_URL,
      });
    },
    processFile(event) {
      if (!event.target.files[0]) return false;
      this.form.cover_photo = event.target.files[0];
      let blob = URL.createObjectURL(this.form.cover_photo);
      let imageHolder = $(".logoarea");
      imageHolder.attr("src", blob);
    },
    inputClick() {
      $("#logo").click();
    },
    dropped(event) {
      let file = event.target.files[0];
      if (!file) return false;
      if (!this.compareTypeCompatibality(file, this.acceptable_file_type)) {
        $("#uploadFile").val("");
        this.errorMessages.push("invalid file type");
        return false;
      }
      this.form.files = event.target.files[0];
      let fileName = URL.createObjectURL(event.target.files[0]);
      let preview = document.getElementById("preview");
      let previewImg = false;
      if (this.acceptable_file_type.includes("pdf"))
        previewImg = document.createElement("embed");
      if (this.acceptable_file_type.includes("image"))
        previewImg = document.createElement("img");
      if (
        file.type.includes("audio") &&
        this.acceptable_file_type.includes("audio")
      )
        previewImg = document.createElement("audio");
      if (
        file.type.includes("video") &&
        this.acceptable_file_type.includes("video")
      )
        previewImg = document.createElement("video");
      if (previewImg && previewImg.controls != undefined)
        previewImg.controls = true;
      let docInfo = document.createElement("p");
      docInfo.innerHTML = this.docInfo(file);
      if (previewImg) previewImg.setAttribute("src", fileName);
      if (previewImg && previewImg.tagName.toUpperCase() == "EMBED") {
        previewImg.style.width = "100%";
        previewImg.style.minHeight = "800px";
        previewImg.setAttribute("type", file.type);
      }
      preview.innerHTML = "";
      preview.appendChild(docInfo);
      if (previewImg) preview.appendChild(previewImg);
      this.drop(event);
    },
    compareTypeCompatibality(file, acceptableType) {
      return (
        acceptableType.includes(file.type) ||
        acceptableType.includes(file.name.split(".").pop()) ||
        (acceptableType.split("/")[0] == file.type.split("/")[0] &&
          file.type.split("/")[0].toLowerCase() != "application")
      );
    },
    drag(event) {
      event.target.parentNode.className = "draging dragBox";
    },
    drop(event) {
      event.target.parentNode.className = "dragBox";
    },
    docInfo(file) {
      let file_complete_name = file.name;
      let file_name_split = file_complete_name.split(".");
      let file_extension = file_name_split.pop().toLowerCase();
      let file_name = file_name_split.join(".");
      let file_size = this.bytesToSize(file.size);
      return (
        '<div class="file-info"><div>Format: <span>' +
        file_extension.toUpperCase() +
        "</span></div><div>Doküman Adı: <span>" +
        file_name +
        "</span></div><div>Boyut: <span>" +
        file_size +
        "</span></div></div>"
      );
    },
    bytesToSize(bytes) {
      let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
    isValidFields(fields) {
      let self = this;
      let form = self.form;
      self.resetMessages();

      if (form.title.length === 0) {
        return self.showFieldsFailedError(
          0,
          self
            .$t("validation.required_field")
            .replace(":NAME", "{{this.$t('collectionDetail.content_name')}}")
        );
      }

      if (false) {
        return self.showFieldsFailedError(
          0,
          self
            .$t("validation.required_field")
            .replace(":NAME", "Kapak Görseli")
        );
      }

      if (!form.file_category_id) {
        return self.showFieldsFailedError(
          1, self.$t("validation.required_field").replace(":NAME", "İçerik Türü")
        );
      }

      if (this.acceptable_file_type === "url" && (form.url == null || !this.validateURL(form.url))) {
        return self.showFieldsFailedError(
          1, self.$t("validation.required_field").replace(":NAME", self.$t(self.selected_content_type.key))
        );
      }
      if (this.acceptable_file_type !== "form" && this.acceptable_file_type !== "url" && this.acceptable_file_type !== "id" &&
        (form.files.length === 0 ||
          !this.compareTypeCompatibality(form.files, this.acceptable_file_type))
      ) {
        return self.showFieldsFailedError(
          1,
          self.$t("validation.required_field").replace(":NAME", "URL")
        );
      }

      if (this.acceptable_file_type === "id" && form.url == null) {
        return self.showFieldsFailedError(
          1, self.$t("validation.required_field").replace(":NAME", "Anzera Eğitimi ID")
        );
      }

      return true;
    },
    showFieldsFailedError(index, message) {
      this.errorMessages.push(message);
      this.scrollToTop();
      this.switcherChange(index);
      return false;
    },
    validateAndSwitch(index) {
      if (this.isValidFields(index - 1)) this.switcherChange(index);
    },
    switcherChange(index) {
      UIkit.switcher($("#uk-switcher-tab")[0]).show(index);
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    this.getFileCategories();
  },
  watch: {
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 2500);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.dragBox input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
}

.dragBox {
  width: auto;
  padding: 0 10px;
  height: 100px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  font-weight: bold;
  line-height: 95px;
  color: #999;
  border: 2px dashed #ccc;
  display: inline-block;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.uploadOuter strong {
  padding: 0 10px;
}

.uploadOuter {
  text-align: center;
  padding: 20px;
}
</style>
<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
