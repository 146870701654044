import ApiService from "@/core/services/api.service";
import { responseError } from '@/core/mixin/shared'
import querystring from "querystring";

export const BASE_URL = 'api/content';
export const FILE_TYPES_URL = 'api/content/file-category';
export const CONTENT_CATEGORIES_URL = 'api/content/content-category';
export const MODULE_NAME = 'FILE_CONTENT_CATEGORY';
export const LOADING = `LOADING`;
export const ERROR = `ERROR`;
export const SUCCESS = `SUCCESS`;
export const FILE_TYPES = `FILE_TYPES`;
export const CONTENT_CATEGORIES = `CONTENT_CATEGORIES`;

export const SET_LOADING = `M/LOADING`;
export const SET_ERROR = `M/ERROR`;
export const SET_SUCCESS = `M/SUCCESS`;
export const SET_FILE_TYPES = `M/FILE_TYPES`;
export const SET_CONTENT_CATEGORIES = `M/CONTENT_CATEGORIES`;
export const RESET_VALUES = `M/RESET_VALUES`;

// ACTIONS
export const GET_FILE_TYPES = `GET_FILE_TYPES`;
export const GET_CONTENT_CATEGORIES = `GET_CONTENT_CATEGORIES`;

const state = {
    file_types: [],
    content_categories: [],
    loading: false,
    error: null,
    success: null,
};

const getters = {
    [LOADING]: (state) => {
        return state.loading;
    },
    [ERROR]: (state) => {
        return state.error;
    },
    [SUCCESS]: (state) => {
        return state.success;
    },
    [FILE_TYPES]: (state) => {
        return state.file_types;
    },
    [CONTENT_CATEGORIES]: (state) => {
        return state.content_categories;
    },
};

const mutations = {
    [SET_LOADING]: (state, payload) => {
        state.loading = payload;
    },
    [SET_ERROR]: (state, payload) => {
        state.error = payload ? responseError(payload) : null;
    },
    [SET_FILE_TYPES]: (state, payload) => {
        state.file_types = payload;
    },
    [SET_CONTENT_CATEGORIES]: (state, payload) => {
        state.content_categories = payload;
    },
    [SET_SUCCESS]: (state, payload) => {
        state.success = payload;
    },
    [RESET_VALUES]: (state) => {
        state.success = null;
        state.error = null;
        state.loading = false;
    },
};

const actions = {
    [GET_FILE_TYPES]: (context, payload) => {
        let url = payload.url || FILE_TYPES_URL;
        let filters = payload.filters;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.get(`${url}?` + querystring.stringify(filters))
            .then((response) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_FILE_TYPES, response.data);
            })
            .catch((error) => {
                 context.commit(SET_ERROR, error);
                context.commit(SET_LOADING, false);
            });
    },
    [GET_CONTENT_CATEGORIES]: (context, payload) => {
        let url = payload.url || CONTENT_CATEGORIES_URL;
        let filters = payload.filters;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.get(`${url}?` + querystring.stringify(filters))
            .then((response) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_CONTENT_CATEGORIES, response.data);
            })
            .catch((error) => {
                 context.commit(SET_ERROR, error);
                context.commit(SET_LOADING, false);
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};